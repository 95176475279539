.form-container {
  max-width: 500px; /* Adjust as needed */
  margin: 0 auto; /* Centered horizontally */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  /* Added shadow properties */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.form-container:hover {
  transform: translateZ(5px); /* Slight elevation on hover */
}

.body { /* Ensure the body element has styles */
  min-height: 100vh; /* Set minimum body height for full viewport */
  display: flex; /* Enable flexbox on body */
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content */
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-family: 'Roboto', sans-serif; /* Changed to Roboto */
  font-size: 14px; /* Adjust font size as needed */
}

input,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif; /* Changed to Roboto */
}

textarea {
  height: 150px;
  resize: vertical;
}

.error {
  color: red;
  font-size: 0.8rem;
  font-family: 'Roboto', sans-serif; /* Changed to Roboto */
}

button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif; /* Changed to Roboto */
}

button:hover {
  background-color: #45a049;
}
